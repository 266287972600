import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";

const kv_bg_h = "../../images/about/kv_bg_h.png";
const kv_bg_v = "../../images/about/kv_bg_v.png";
const cp_bg_h = "../../images/about/cp_bg_h.png";
const cp_bg_v = "../../images/about/cp_bg_v.png";

const bg_text = "../../images/about/bg_text.png";
const bg_text_sp = "../../images/about/bg_text2.png";

const kv = "../../images/about/kv.png";
const img1 = "../../images/about/img1.png";
const img2 = "../../images/about/img2.png";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "none"; // blurred tracedSVG none
	const TABLE_ROW_COMPANY_CLASS = CONST.TABLE_ROW_CLASS + " xl:w-7/12 border-dotted "

	return (
		<Layout>
			<SEO
				keywords={[`IT`, `ネットワーク`, `開発`, `東京`]}
				title="About"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.ABOUT_KV_BACK_GROUND_PATTERN_CLASS} src={kv_bg_h} />
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.ABOUT_KV_BACK_GROUND_PATTERN_CLASS} src={kv_bg_v} />
				<div className={CONST.KEY_VISUAL_OFFSET_TITLE_AREA_CLASS}>
					<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>About us<span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>私たちについて</span></p>
				</div>
			</section>
			<section className={CONST.SECTION_AREA_REVERSE_CLASS + " xl:-mt-32"}>
				<div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_LEFT_CLASS + " sm:font-light sm:pt-14 sm:pb-20 3xl:py-48"}>
					<div>
						<h1 className={CONST.H5_CLASS + " mb-8"}><span>私たちは</span><br></br>&quot;常に上を向いて&quot;、&quot;常に前向きに&quot;、そして&quot;常にベスト&quot;を尽くします。</h1>
						<p>&quot;仕事を&quot;&quot;遊びを&quot;&quot;生活を&quot;楽しめる社員、&quot;経営を&quot;楽しめる経営陣、</p>
						<p>そんな企業が行う「楽しめるビジネス」が必ず世界を楽しくさせるはずです。</p>
						<p className="mb-20">関係する人たちが「楽しめるビジネス」に取り組んでいます。</p>
						<p className="float-right">CEO 柴山芳則</p>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv} />
				</div>
			</section>

			<section data-aos="fade-up" className={CONST.SECTION_P_AREA_CLASS + " xl:-mt-10"}>
				<div className="w-full xl:w-6/12"></div>
				<div className="w-full xl:w-6/12 z-10">
					<p>「ビジョン」</p>
					<p>・誰もが使えるＩＴを社会に広める！</p>
					<p className={CONST.LINE_SPACING_PB_CLASS}>・ＩＴ社会を護り続ける！</p>
					<p>Information technology is the norm in everyday life.</p>
				</div>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.ABOUT_BACK_GROUND_TEXT_CLASS} src={bg_text_sp} />
			</section>

			<div className={CONST.PC_SHOW_CLASS}>
				<section className={CONST.SECTION_P_AREA_CLASS + " -mt-24"}>
					<div className="w-7/12"></div>
					<div className="w-5/12">
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full z-0 xl:ml-24 3xl:ml-32" src={bg_text} />
					</div>
				</section>
			</div>

			<section className={CONST.SECTION_AREA_CLASS} >
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS + " z-10"}>
					<h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>お客さまに提供する価値／バリュー（value）</h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img1} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + "  z-10"}>
					<div>
						<h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>お客さまに提供する価値／バリュー（value）</h2>
						<p className="text-md xl:text-xl font-midium">お客さま視点×独立経営＝お客さまファースト</p>
						<p className="mt-3">特定のベンダーに依存したハードウェアやソフトウェアに縛られることなく</p>
						<p className={CONST.LINE_SPACING_PB_CLASS}>お客さまに最適なご提案をします。</p>
						<p className="text-md xl:text-xl font-midium">提案×開発×運用＝一気通貫で提供するビジネスモデル</p>
						<p className="mt-3">すべてのプロセスを一気通貫で提供する体制が整っています。</p>
						<p className={CONST.LINE_SPACING_PB_CLASS}>運用が始まった後も、お客さまのご要望を素早く柔軟に対応いたします。</p>
						<p className="text-md xl:text-xl font-midium">セキュリティ×運用×教育＝セキュリティコンサルティング</p>
						<p className="mt-3">企業の信頼を守るため、お客さまとともにセキュリティリスクに立ちか向かいます。</p>
						<p>お客様自身で自走できるよう、ご担当者様の教育支援も行います。</p>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_REVERSE_CLASS + CONST.PB_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<h2 className={CONST.PC_SHOW_CLASS + CONST.H2_CLASS + CONST.LINE_SPACING_PB_CLASS}>行動指針</h2>
						<p>１．ユーザーにとって価値あるものを提供する</p>
						<p>２．計画八割、走りながら改善する（悩むより先に行動する）</p>
						<p>３．行動に際しては常にアウトプットし、発信を行う</p>
						<p>４．誠実な行動を心掛ける</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>行動指針</h2>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img2} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS + " bg-gray-light"}>
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.ABOUT_COMPANY_BACK_GROUND_PATTERN_CLASS} src={cp_bg_h} />
				<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.ABOUT_COMPANY_BACK_GROUND_PATTERN_CLASS} src={cp_bg_v} />
				<div data-aos="fade-up" className="w-full z-10 ">
					<h2 className={CONST.H2_CLASS + " py-10 xl:py-0 -mb-8 xl:-mb-16"}>会社概要</h2>
				</div>
				<div data-aos="fade-up" className={CONST.SECTION_AREA_CLASS + " mt-10 xl:mt-24 xl:ml-12 "}>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>会社名</div>
						<div className={CONST.TABLE_TEXT_CLASS}>株式会社ホライゾン</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>設立</div>
						<div className={CONST.TABLE_TEXT_CLASS}>1997年6月23日</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>資本金</div>
						<div className={CONST.TABLE_TEXT_CLASS}>10,000千円</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>所在地</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>■本社</p>
							<p>〒108-0073　東京都港区三田2-18-5　アルファ・ウェーブビル</p>
							<p>TEL:03-6722-6777</p><br></br>
							<p>■三田開発センター</p>
							<p>〒108-0073　東京都港区三田4-1-9　三田ヒルサイドビル10階</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>売上高</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>156 百万円 (ホライゾン単体／2021 年 12 月実績)</p>
							<p>1,535 百万円 (グループ全体／2022 年 5 月実績)</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>代表者</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>代表取締役社長　柴山芳則</p>
							<p>代表取締役　　　河本憲二郎</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>従業員数</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>42 名 (ホライゾン単体／2022 年 12 月現在)</p>
							<p>191 名 (グループ全体／2022 年 5 月現在)</p>
						</div>
					</div>
					{/* <div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>平均年齢</div>
						<div className={CONST.TABLE_TEXT_CLASS}>32.4歳(2021年5月31日現在)</div>
					</div> */}
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>事業内容</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
						<p>情報セキュリティサービス事業</p>
            			<p>システム運用事業</p>
						<p>ＩＴ製品販売事業</p>
            			<p>ＩＴコンサルティング事業</p>
            			<p>コンピュータソフトウェアの開発事業</p>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>関連会社</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<a href="https://www.alphawave.co.jp/" className="no-underline hover:underline text-blue-500">(株)アルファ・ウェーブ</a>
						</div>
					</div>
					<div className="w-full xl:w-6/12"></div>
					<div className={TABLE_ROW_COMPANY_CLASS}>
						<div className={CONST.TABLE_TITLE_CLASS}>各種認証・資格</div>
						<div className={CONST.TABLE_TEXT_CLASS}>
							<p>労働者派遣事業 [許可番号：派13-315599]</p>
							<p>ISO/IEC 27001:2013 [登録番号：JQA-IM1605]</p>
						</div>
					</div>
				</div>
				<div className="w-full xl:w-6/12"></div>
			</section>


		</Layout >
	);
}

export default IndexPage;

